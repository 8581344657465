import { BiLogIn } from "react-icons/bi";
import { toast } from "react-toastify";


function Logout() {
  return (
    <div className="logout__btn"  style={{
        fontSize: "1.8rem",
        position: "absolute",
        left: "85%",
        top: "2%",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems:"center"

      }}
      onClick={() => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        toast.success("Logged out.");
        window.location.assign("/");
      }}
      >
      <BiLogIn
      style={{
        marginBottom: "10px"
      }}
        
      />
    </div>
  );
}

export default Logout;
