import { Container, Card } from "react-bootstrap";
import Logo from "../assets/logo.svg";
import Floaters from "../layouts/FloatingButton";
import "../assets/css/Home.css";
import { Link } from "react-router-dom";
import fetchCompany from "../utils/Companies";
import { useState } from "react";
import { useEffect } from "react";
import IndividualCheck from "../utils/IndividualCheck";
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

function Home() {
  const [companies, setCompanies] = useState();
  const [id, setId] = useState("1");
  const [boid, setBoid] = useState("");
  const [result , setResult] = useState("")
  const [status, setStatus] = useState()
  const [isLogin, setIsLogin] = useState(false)
  const [user, setUser] = useState("");
  const [checking , setIsChecking] = useState(false)
  const [companyLoading , setCompanyLoading] = useState(true)
  const [internalServerError, setInternalServerError] = useState(false)
  const history = useHistory()

  // check if anyone is logged in
  useState(() => {
    const userInLocalStorage = localStorage.getItem('user')
    if(!userInLocalStorage) return setIsLogin(false)
    setIsLogin(true)
    setUser(userInLocalStorage)
  },[])


  // fetch the company details
  useEffect(() => {
    fetchCompany().then((data) => {
      if(data.error) {
       toast.warn(data.error)
       setInternalServerError(true)
       return
      }
      setCompanyLoading(false)
      setCompanies(data)
      console.log(companies)

    })
  }, []);

  // individual result check
  const checkResult = () => {
    if(internalServerError) return toast.warn("Our servers are down. Our developers will fix it asap.")

    if(!id || !boid) {
    return toast.error("Please add all fields.") 
    }
    setResult("")
    setStatus()
    setIsChecking(true)
    IndividualCheck(id, boid)
    .then(data => {
      setIsChecking(false)
      if(data.error) return toast.error(data.error)
      setResult(data.data.message)
     
      if(data.data.success == false) {
        setStatus(false)
      }
      else {
        setStatus(true)
      }
    })
    console.log(internalServerError)
  }

  const resultStyle = {
    // border: status ? "1px solid #198654" : "1px solid red",
    color: status ? "#198654" : "red"
  }

  return (
    <Container style={{marginLeft: "-10px"}}>
      <div className="stock"></div>
      <div className="home__main">
        {/* text area */}
        <div className="text__box" xs={7}>
          <img src={Logo} className="logo" />
          <p style={{ color: "#545454" }}>
            A Quality Product of <strong>NEPDEV TECHNOLOGY</strong>
          </p>
        </div>

        {/* individual ipo result checker */}
        <div className="ipo__checker" xs={5}>
          <Card className="individual__checker" style={{position: "relative"}}>
            <p style={{
              position: "absolute",
              top: "10%",
              color: "#2D2DD8",
              fontWeight: "700",
              fontSize: "1.2rem"
            }}>{isLogin ? `Welcome\n ${user}` : ""}</p>
            <div className="branding">
              <Link to="/">
                <img src={Logo} className="logo2" />
              </Link>
              <p>Allotment Result Checker.</p>
            </div>

            <select
              className="form-select"
              aria-label="Default select example"
              onChange={(e) => setId(e.target.value)}
            >
              {
                companyLoading ? <option value="">{ internalServerError ? "Currently our servers are down" : "loading...."}</option>
                : 
                companies && companies.map((company, key) => (
                  <>
                   <option value={company.id} key={key}>
                     {company.name}
                   </option>
                  </>
                ))
              }
            </select>

            <input
              type="number"
              className="form-control"
              placeholder="BOID"
              onChange={(e) => setBoid(e.target.value)}
            />

            <input
              type="button"
              className="btn btn-primary"
              value={checking ? "Checking....":"Check Result" }
              onClick={checkResult}
            />

            {
              result && <div className="result" style={resultStyle}>{result}</div>
            }
          </Card>
        </div>
      </div>
      <Floaters />
    </Container>
  );
}

export default Home;
