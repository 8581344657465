import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../config";
import { useHistory } from 'react-router-dom'

function Reset() {
  const [password, setPassword] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const resetToken = queryParams.get("resetToken");
  const history = useHistory()

  const handleUpdatePassword = () => {
      if(!password) return toast.warn("Please enter new password.")
      fetch(`${baseUrl}/reset`, {
          method: "POST",
          headers: {
              'content-type':'application/json'
          },
          body: JSON.stringify({ id, resetToken, newPassword: password })
      })
      .then(res => res.json())
      .then(data => {
          if(data.error) return toast.error(data.error)
          toast.success(data.message)
          setTimeout(() => {
              window.location.assign('/login')
          }, 2000)
      })
      .catch(err => console.log(err))
  }

  const queryParams2 = new URLSearchParams(window.location.search)
  const isQuery = queryParams2.get('resetToken')
  useEffect(() => {
    if(!isQuery) return history.push('/')
  })

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="reset__form"
        style={{
          height: "200px",
          width: "300px",
          border: "1px solid lightgray",
          boxShadow:
            "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <input
          type="password"
          placeholder="Enter new password"
          className="form-control"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="submit"
          value="Reset Password"
          className="btn btn-primary"
          onClick={handleUpdatePassword}
        />
      </div>
    </div>
  );
}

export default Reset;
