import { useState } from "react";
import { toast } from "react-toastify";
import "../assets/css/Accounts.css";
import { baseUrl } from "../config";

function Accounts({ boid, id, updateList, index}) {
  const [dUname, setDUname] = useState()
  const [dBoid2, setDBoid2] = useState()
  const [deleting, setDeleting] = useState(false)
  
  
  const deleteAccounts = () => {
    if(!dUname || !dBoid2) return toast.warn("Unexpected error.  try again.")
    console.log(dUname, dBoid2)
setDeleting(true)
let a = dUname.split(".")[1].trim()
    fetch(`${baseUrl}/api/delete`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem('token')}`

      },
      body: JSON.stringify({uname: a, boid2: dBoid2})
    })
    .then(res => res.json())
    .then(data => {
      setDeleting(false)
      if(data.error) return toast.error(data.error)
      updateList()
      toast.success(`Removed boid for ${dUname}`)
    })
    .catch(err => console.log(err))
    
  }
  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6" key={id} id={index + 1} >
      <div className="results">
        <p
          style={{
            color: "#7B7B7B",
            fontWeight: "500",
            fontSize: "15px",
          }}
          
        >
         <span>{index+ 1}.</span> {boid.uname}
        </p>
        <p
          style={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#545454",
          }}
         
        >
          {boid.boid2}
        </p>
        <p className="delete" onClick={async (e) => {
          const listOfChild = e.target.parentNode.querySelectorAll('p')
          setDUname(listOfChild[0].innerText)
          setDBoid2(listOfChild[1].innerText)
          deleteAccounts()
        }}>
          {deleting ? "Deleting" : "X"}
        </p>
      </div>
    </div>
  );
}

export default Accounts;
