import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import './App.css'
import Home from './pages/Home'
import Login from './pages/Login'
import Signup from './pages/Signup'
import BulkChecker from './pages/BulkChecker'
import SavedAccounts from './pages/SavedAccounts'
import 'bootstrap/dist/css/bootstrap.min.css'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react'
import { useEffect } from 'react'
import Logout from './layouts/Logout'
import Reset from './pages/Reset'
import { BiHome } from "react-icons/bi";




function App() {
  
  const token = localStorage.getItem('token');
  const [isLogin, setIsLogin] = useState(false)
  const history = useHistory()
useEffect(() => {
  if (!token) return setIsLogin(false)
  setIsLogin(true)
})
  return (
    <>
     <div className="back__btn" style={{
       position: "absolute",
       top: "2%",
       left: "2%",
       borderRadius: "50%",
       height: "50px",
       width: "50px",
       boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
       display: "flex",
       alignItems:"center",
       justifyContent: "center",
       fontSize: "1.7rem",
       cursor: "pointer",
       
     }}
     onClick={() => history.push('/')}
     >
      <BiHome />
      </div>
   {isLogin ? <Logout /> : " "}
      <Switch>
        <Route path="/" component={Home} exact  />
        <Route path="/login" component={Login} exact  />
        <Route path="/signup" component={Signup} exact  />
        <Route path="/bulk" component={BulkChecker} exact />
        <Route path="/saved" component={SavedAccounts} exact />
        <Route path="/reset" component={Reset} exact />
      </Switch>
      <ToastContainer />
    </>
  )
}

export default App