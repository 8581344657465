import { useState } from "react";
import "../assets/css/FloatingButton.css";
import { Link } from "react-router-dom";

function Floaters({}) {
  const [isOpen, setOpen] = useState(false);

  const handleFloatingButtonClick = () => {
    isOpen ? setOpen(false) : setOpen(true);
  };

  const floating__button = {
    color: isOpen ? "#2D2DD8" : "#fff",
    backgroundColor: isOpen ? "#fff" : "#2D2DD8",
  };

  return (
    <div className="floaters">
      <div className="floating__card" style={{display: isOpen ? "flex" : "none"}} onMouseLeave={() => setOpen(false)}>
          <Link className="bulk__check" to="/bulk">Bulk Check</Link>
          <Link className="saved__accounts" to="/saved">Saved Accounts</Link>
      </div>
      <div
        className="floating__button"
        onClick={handleFloatingButtonClick}
        onMouseEnter={handleFloatingButtonClick}
        style={floating__button}
      >
        {isOpen ? "x" : "+"}
      </div>
    </div>
  );
}

export default Floaters;
