import { baseUrl } from "../config"

export default async function BulkCheck(token, id) {
    let data;
    await fetch(`${baseUrl}/test`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'content-type': 'application/json'
        },
        body: JSON.stringify({ id})
    })
    .then(res => res.json())
    .then(result => data = result)
    .catch(err => console.log(err))
    return data
}