import { baseUrl } from '../config'

export default async function fetchCompany() {
    let companies;
   await fetch(`${baseUrl}/api/country`)
    .then(res => res.json())
    .then(data => {
        console.log(data)
        companies = data
    })
    .catch(err => console.log(err))
    return companies
} 