import { baseUrl } from '../config'

export default async function IndividualCheck(id, boid) {
    let data;
   await fetch(`${baseUrl}/api2?id=${id}&boid=${boid}`)
   .then(res => res.json())
   .then(result => {
       data = result
   })
   .catch(err => console.log(err))
   return data
    
} 