import Logo from "../assets/logo.svg";
import "../assets/css/BulkChecker.css";
import { Container, Modal, Button } from "react-bootstrap";
import Floaters from "../layouts/FloatingButton";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import BulkCheck from "../utils/BulkCheck";
import fetchCompany from "../utils/Companies";
import { baseUrl } from "../config";
import { toast } from "react-toastify";

function BulkChecker() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const [companies, setCompanies] = useState();
  const [id, setId] = useState("1");
  const [hasBulkAccount, setHasBulkAccount] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [internalServerError, setInternalServerError] = useState(false);
  let checked = 0
  let total = 0
  let alloted = 0
  let boid_text = document.querySelectorAll(".hello")
  const [isBlur, setIsBlur] = useState(false)

  // check if the user has added bulk accounts
  useEffect(() => {
    fetch(`${baseUrl}/api/boid`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.length === 0) return setHasBulkAccount(false);
        setHasBulkAccount(true);
      })
      .catch((err) => console.log(err));
  }, []);

  // fetch the company details
  useEffect(() => {
    fetchCompany().then((data) => {
      if (data.error) {
        toast.warn(data.error);
        setInternalServerError(true);
        return;
      }
      setCompanies(data);
    });
  }, []);

  // check if user is logged in or not
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token || token === "") {
      return setShow(true);
    } else {
      setShow(false);
    }
  });

  const handleBulkResult = () => {
    if (internalServerError)
      return toast.warn(
        "Our servers are down. Our developers will fix it asap."
      );
    if (!id) return toast.error("Please select a company.");
    if (!localStorage.getItem("token"))
      return toast.error("You are not logged in.");
    setLoading(true);
    BulkCheck(localStorage.getItem("token"), id)
      .then((data) => {
        if(data.error) {
        return  toast.error(data.error)
        }
        setData(data);
        setLoading(false);
        data.forEach(data => {
          if(data.data.success) {
            alloted += 1
            checked += 1
            total += 1
          } else {
            total += 1 
            checked += 1
          }
        })

        const alloted_div = document.querySelector("#alloted");
        const total_boid_div = document.querySelector("#total_boid");
        const checked_div = document.querySelector("#checked");
        alloted_div.innerText = alloted;
        total_boid_div.innerText = total;
        checked_div.innerText = checked;
      })
      .catch((err) => {
        console.log(err);
        alert("Internal server error.");
      });
  };

  return (
    <Container style={{ minHeight: "100vh" }}>
      <header>
        <div className="branding">
          <Link to="/">
            <img src={Logo} alt="logo of nepdev." style={{ height: "25px" }} />
          </Link>

          <p style={{ color: "#545454", fontSize: "20px", fontWeight: "400" }}>
            Allotment Result Checker.
          </p>
        </div>
      </header>

      <main>
        <select
          className="form-select"
          aria-label="Default select example"
          onChange={(e) => setId(e.target.value)}
        >
        {internalServerError ? (
            <option value="">
              {" "}
              Our servers are down. Developers will fix it asap.{" "}
            </option>
          ) : (
            companies &&
            companies.map((company, key) => (
              <>
                <option value={company.id} key={key}>
                  {company.name}
                </option>
              </>
            ))
          )}
        </select>
        <input
          type="submit"
          className="btn btn-primary"
          value="Start Bulk Check"
          style={{ fontWeight: "500" }}
          onClick={() => {
            setData([]);
            alloted = 0;
            total = 0;
            checked = 0 ;
            handleBulkResult()
          }}
        />
      </main>
            

      <section style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-around"
      }}>
        <div className="stats">
          <h5>Checked: <span id="checked">0</span></h5>
        </div>
        <div className="stats">
          <h5>Alloted: <span id="alloted">0</span></h5>
        </div>
        <div className="stats">
          <h5>Total Boid: <span id="total_boid">0</span></h5>
        </div>
        <div className="boid_eye" onClick={() => {
          boid_text.forEach(span => {
           if(isBlur) {
             setIsBlur(false)
             span.classList.toggle('boid_text')
           } else {
             setIsBlur(true)
             span.classList.toggle('boid_text')
           }
          })
        }}>
        {isBlur ?   <i class="fa-solid fa-eye-slash"></i> : <i class="fa-solid fa-eye"></i> }
        </div>
      </section>

      <section>
        <div className="bulk__results row">
          {hasBulkAccount ? (
            ""
          ) : (
            <h1>
              Please add bulk accounts <Link to="/saved">here</Link>
            </h1>
          )}
          {isLoading ? <h1>Loading results...</h1> : ""}
          {hasBulkAccount
            ? data &&
              data.map((res, key) => {
                return (
                  <div
                    className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
                    key={key}
                  >
                    <div className={`results ${res.data.success}`}>
                      <p
                        style={{
                          color: res.data.success ? "#2BF688" : "red",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                      >
                       <span><b>{key+1}.</b></span> NAME: {res.name}
                      </p>
                      <p
                        style={{
                          fontSize: "10px",
                          fontWeight: "700",
                          color: res.data.success ? "#2BF688" : "red",
                          marginTop: "-10px"
                        }}
                      >
                      
                    <span id="boid_space"></span>BOID: <span className={`hello boid_text ${res.data.success ? "parekoxa": ""}`}>{res.id}</span>
                    
                      </p>
                      <div className={`message ${res.data.success}`}>
                      <span id="boid_space"></span>{res.data.message}
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
        <Floaters />
      </section>

      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Unauthorized</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          To use this feature, please make sure you are logged in.
        </Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <Button variant="danger">Homepage</Button>
          </Link>
          <Link to={`login/?redirect=${window.location.pathname}`}>
            <Button variant="primary">Login</Button>
          </Link>
          <Link to="/signup">
            <Button variant="primary">Signup</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default BulkChecker;
