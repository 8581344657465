import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import Logo from "../assets/logo.svg";
import Floaters from "../layouts/FloatingButton";
import Accounts from "../layouts/Accounts";
import "../assets/css/SavedAccounts.css";
import { Link } from "react-router-dom";
import { useState, useEffect} from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../config";

function SavedAccounts() {

  const [show, setShow] = useState(false);
  const [uname, setUname] = useState("");
  const [boid2, setBoid2] = useState("");
  const [boids, setBoids] = useState([])
  const [adding, setAdding] = useState(false)


  // check if user is logged in or not
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token || token === "") {
      return setShow(true);
    } else {
      setShow(false);
    }
  });

 
  // handle add
  const handleAdd = () => {
    if(!uname || !boid2) return toast.error("Please add all fields.")
    if(!localStorage.getItem("token")) return toast.error("You are not logged in.")

    setAdding(true)

    fetch(`${baseUrl}/api/add`, {
      method:'POST',
      headers: {
        'content-type': 'application/json',
        'authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify({uname , boid2})
    })
    .then(res => res.json())
    .then(result => {
      if(result.error) return toast.error(result.error)
      toast.success(result.message)
      setAdding(false)
      setUname("")
      setBoid2("")
    })
    .catch(err => {
      console.log(err)
      toast.error("Internal server error.")
    })
  }

     // fetch saved boids
     useEffect(() => {
      fetch(`${baseUrl}/api/boid`, {
        headers: {
          "authorization": `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(res => res.json())
      .then(result =>{
        setBoids(result.data)
      })
      .catch(err => console.log(err))
    },[uname])

    // update saved account list on delete
    const updateList = () => {
      fetch(`${baseUrl}/api/boid`, {
        headers: {
          "authorization": `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(res => res.json())
      .then(result =>{
        setBoids(result.data)
      })
      .catch(err => console.log(err))
    }
  

  return (
    <Container>
      <div className="saved__accounts__main">
        {/* all accounts*/}
        <div className="my__accounts" xs={7}>
          <div className="row">
            {boids && boids.map((boid, index) => (
              <Accounts boid={boid} id={index} index={index} updateList={updateList} />
            ))}
          </div>
        </div>

        {/* add accounts form */}
        <div className="add__form" xs={5}>
          <Card className="save__form">
            <div className="branding">
              <Link to="/">
                <img src={Logo} className="logo2" />
              </Link>
              <p>Allotment Result Checker.</p>
            </div>

            <input
              value={uname}
              type="text"
              className="form-control"
              placeholder="Name"
              onChange={(e) => setUname(e.target.value)}
            />

            <input
            value={boid2}
              type="number"
              className="form-control"
              placeholder="BOID"
              onChange={(e) => setBoid2(e.target.value)}
            />

            <input
              type="button"
              className="btn btn-primary"
              value={adding ? "Saving....":"Add Account"}
              onClick={handleAdd}
            />
          </Card>
        </div>
      </div>

      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Unauthorized</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          To use this feature, please make sure you are logged in.
        </Modal.Body>
        <Modal.Footer>
          <Link to="/">
            <Button variant="danger">Homepage</Button>
          </Link>
          <Link to={`login/?redirect=${window.location.pathname}`}>
            <Button variant="primary">Login</Button>
          </Link>
          <Link to="/signup">
            <Button variant="primary">Signup</Button>
          </Link>
        </Modal.Footer>
      </Modal>

      <Floaters />
    </Container>
  );
}

export default SavedAccounts;
