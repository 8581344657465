import { Container, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.svg";
import "../assets/css/Auth.css";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../config";
import { useHistory } from 'react-router-dom'


function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resendMail , setResendMail] = useState(false);
  const [logging, setLogging] = useState(false)
  const [resending, setResending] = useState(false)
  const [resetting, setResetting] = useState(false)
  const history = useHistory()
  const [show, setShow] = useState(false)
  const [emailToResetPasssword, setEmailToResetPassword] = useState("")
  const queryParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    const token =  localStorage.getItem('token')
    console.log(token)
    if(!token) return 
    history.push('/')
    toast.success("You are already logged in.")
   },[])


  const handleLogin = () => {
      if(!email || !password) return toast.error("Please add all fields.")
    setLogging(true)
      fetch(`${baseUrl}/api/login`, {
          method: 'POST',
          headers: {
              'content-type': 'application/json'
          },
          body: JSON.stringify({ email, password })
      })
      .then(res => res.json())
      .then(data => {
        setLogging(false)
          if(data.verified === false)  setResendMail(true)
          if(data.error) return toast.error(data.error)
          localStorage.setItem('token', data.access_token)
          localStorage.setItem('user', data.user)
          toast.success(`Welcome ${data.user}`)
          window.location.assign(`http://${window.location.host}${queryParams.get('redirect') || '/'}`)
      })
      .catch(err => console.log(err))
      console.log(resendMail)
  }

  const handleResend = () => {
    if(!email) return toast.error("Please add an email.")
    setResending(true)
    fetch(`${baseUrl}/resend`, {
      method: "POST",
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({email})
    })
    .then(res => res.json())
    .then(data => {
      setResending(false)
      if(data.error) return toast.warn(data.error)
      toast.success(data.message)
    })
    .catch(err => {
      console.log(err)
    })
  }

  const openModal = () => {
   setShow(true)
  }

  const handleResetPassword = () => {
    if(!emailToResetPasssword) return toast.warn("Please enter an email.")
setResetting(true)
    fetch(`${baseUrl}/api/forgot`, {
      method: "POST",
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({email: emailToResetPasssword})
    })
    .then(res => res.json())
    .then(data => {
      setResetting(false)
      if(data.error) return toast.error(data.error)
      setShow(false)
      toast.success(data.message)
    })
    .catch(err => console.log(err))

  }

  return (
    <Container className="auth__page">
      <div className="card">
        <div className="branding">
          <Link to="/">
            <img src={Logo} className="logo2" />
          </Link>
          <p>Allotment Result Checker.</p>
        </div>

        <div className="signup__text">Login</div>

        <input
          type="email"
          id="email"
          placeholder="Email"
          className="form-control"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          id="password"
          placeholder="Password"
          className="form-control"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="submit"
          id="btn"
          value={logging ? "Authorizing...":"Login"}
          className="btn btn-primary"
          onClick={handleLogin}
        />
         {
           resendMail ? <input
           type="submit"
           id="btn"
           value={resending ? "Sending....":"Resend email"}
           className="btn btn-success"
           onClick={handleResend}
         /> : ""
         }

        <div className="redirect">
          Don't have an account ? <Link to="/signup">Signup here.</Link>
          <br />
          <p style={{color: "blue", cursor: "pointer"}} onClick={openModal}>{
            resetting ? "Sending reset token ..." : "Reset password"
          }</p>
        </div>
      </div>

      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="email" placeholder="Enter email address" className="form-control" onChange={e => setEmailToResetPassword(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
         
            <Button variant="primary" onClick={handleResetPassword}>{resetting ? "Sending Mail...": "Send"}</Button>
<Button variant="danger" onClick={()=> {
  setShow(false)
  history.push('/login')

}}>Back</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Login;
