import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.svg";
import "../assets/css/Auth.css";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../config";
import { useHistory } from 'react-router-dom'

function Signup() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [IsProcessing, setIsProcessing] = useState(false)
  const history = useHistory()

  const queryParams = new URLSearchParams(window.location.search)

  
  useEffect(() => {
    const token =  localStorage.getItem('token')
    console.log(token)
    if(!token) return 
    history.push('/')
    toast.success("You are already logged in.")
   },[])

  const handleSignup = () => {
      if(!name || !email || !password) return toast.error("Please add all fields.")

      setIsProcessing(true)
      fetch(`${baseUrl}/api/signup`,{
          method: 'POST',
          headers: {
              'content-type': 'application/json'
          },
          body: JSON.stringify({name, email, password})
      })
      .then(res => res.json())
      .then(data => {
          setIsProcessing(false)
          if(data.error) return toast.error(data.error)
          toast.success(data.message)
      })
      .catch(err => console.log(err))
  }

  return (
    <Container className="auth__page">
      <div className="card">
        <div className="branding">
          <Link to="/">
            <img src={Logo} className="logo2" />
          </Link>
          <p>Allotment Result Checker.</p>
        </div>

        <div className="signup__text">Create A New Account</div>

        <input
          type="text"
          id="name"
          placeholder="Full name"
          className="form-control"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          id="email"
          placeholder="Email"
          className="form-control"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          id="password"
          placeholder="Password"
          className="form-control"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="submit"
          id="btn"
          value={IsProcessing ? "Please Wait..." : "Sign Up"}
          className="btn btn-primary"
          onClick={handleSignup}
        />

        <div className="redirect">
          Already have an account ? <Link to="/login">Login here.</Link>
        </div>
      </div>
    </Container>
  );
}

export default Signup;
